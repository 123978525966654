import React, { lazy, Suspense, useState } from 'react'
import { Box, Grid, LinearProgress } from '@mui/material'
import TopBar from '../components/TopBar'
import MobileMenu from '../components/MobileMenu'
import Hello from '../components/Hello'
import { mainGray, mainWhite } from '../components/styled/theme'
import { scrollToDiv } from '../util/functionUtil'

const About = lazy(() => import('../components/About'))
const Services = lazy(() => import('../components/Services'))
const Products = lazy(() => import('../components/Products'))
const KeyFigures = lazy(() => import('../components/KeyFigures'))
const ReadyToStart = lazy(() => import('../components/ReadyToStart'))
const Contact = lazy(() => import('../components/Contact'))
const Footer = lazy(() => import('../components/Footer'))

const renderLoader = () => (
    <Box sx={{ width: '100%', padding: '2.5rem', backgroundColor: 'white' }}>
        <LinearProgress />
    </Box>
)

const Home = () => {
    const [service, setService] = useState('')

    const handleChangeService = (newService) => {
        setService(newService)
        scrollToDiv('contactSection', 120)
    }

    const getMenu = () => {
        return window.innerWidth <= 780 ? (
            <MobileMenu />
        ): (
            <TopBar />
        )
    }

    return (
        <Grid container>
            <nav>
                {getMenu()}
            </nav>
            <section id='accueil' className='background'>
                <Grid item xs={12} className='blacBackgroundOpacity'>
                    <Hello />
                </Grid>
            </section>
            <Suspense fallback={renderLoader()}>
                <section id='productsSection' style={{ backgroundColor: mainGray }}>
                    <Grid item xs={12}>
                        <Products />
                    </Grid>
                </section>
            </Suspense>
            <Suspense fallback={renderLoader()}>
                <section id='presentationSection'>
                    <Grid item xs={12}>
                        <About />
                    </Grid>
                </section>
            </Suspense>
            <Suspense fallback={renderLoader()}>
                <section id='keyFiguresSection' className='background'>
                    <Grid item xs={12} className='blacBackgroundOpacity'>
                        <KeyFigures />
                    </Grid>
                </section>
            </Suspense>
            <Suspense fallback={renderLoader()}>
                <section id='servicesSection' style={{ backgroundColor: mainWhite }}>
                    <Grid item xs={12}>
                        <Services handleChangeService={handleChangeService} />
                    </Grid>
                </section>
            </Suspense>
            <Suspense fallback={renderLoader()}>
                <section id='readyToStart' style={{ backgroundColor: '#333333' }}>
                    <Grid item xs={12}>
                        <ReadyToStart />
                    </Grid>
                </section>
            </Suspense>
            <Suspense fallback={renderLoader()}>
                <section id='contactSection' style={{ backgroundColor: mainWhite }}>
                    <Grid item xs={12}>
                        <Contact serviceSubject={service} />
                    </Grid>
                </section>
            </Suspense>
            <Suspense fallback={renderLoader()}>
                <section id='footer' style={{ backgroundColor: mainGray }}>
                    <Grid item xs={12}>
                        <Footer />
                    </Grid>
                </section>
            </Suspense>
        </Grid>
    )
}

export default Home