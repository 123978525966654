import React from 'react'
import { Grid } from '@mui/material'
import { mainWhite } from './styled/theme'
import { ContactButton } from './styled/buttons'
import productionLine from '../assets/industries/productionLine.webp'

const Hello = () => (
    <Grid
        container
        alignItems='center'
        justifyContent='center'
        sx={{
            height: {
                md: '100vh',
                xs: 'auto',
            },
            padding: {
                xl: '0 10vh',
                lg: '3vh',
                sm: '10vh 3vh',
                xs: '15vh 0vh',
            },
        }}
    >
        <Grid container item xs={10}>
            <Grid item xs={12} className='title'>
                <h1>LP Pneumatic Automation votre fournisseur de référence pour des vérins pneumatique et systèmes d'automatisations fiables et efficaces</h1>
            </Grid>
            <Grid container item xs={12} justifyContent='space-between' sx={{ height: { md: '500px', xs: 'auto' } }}>
                <Grid container item md={7.5} xs={12} sx={{ position: 'relative', background: `url(${productionLine})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: { md: 'auto', xs: '300px' } }}>
                    <div className='parallelogramBackground'>
                        <h3>Chaîne de fabrication automatisée de notre partenaire Airtac</h3>
                    </div>
                </Grid>
                <Grid container item md={4} xs={12} sx={{ padding: '2rem', backgroundColor: mainWhite, marginTop: { md: '0', xs: '2rem' }, height: { md: 'auto', sm: '400px', xs: 'auto' } }}>
                    <Grid item xs={12}>
                        <span>01.03.23</span>
                    </Grid>
                    <Grid item xs={12}>
                        <h2>LP Pneumatic Automation & AIRTAC</h2>
                    </Grid>
                    <Grid item xs={12} sx={{ margin: { md: '1rem 0', xs: '0 0 1rem' } }}>
                        <p style={{ maxHeight: '190px', overflow: 'hidden', margin: 0 }}>Depuis nos débuts, nous travaillons avec Airtac, un fabricant renommé dans le monde entier pour ses équipements pneumatiques professionnels.
                            Sa production se concentre principalement sur les actionneurs pneumatiques, les composants de commande et de traitement, ainsi que divers composants auxiliaires.</p>
                    </Grid>
                    <Grid item xs={12} sx={{ margin: '1rem 0' }}>
                            Retrouvez en plus sur leur site: <a target='_blank' href='https://eu-fr.airtac.com/' rel='noreferrer'>airtac.com</a>
                    </Grid>
                    <ContactButton />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

export default Hello