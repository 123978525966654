import { Button, ButtonGroup, TextField } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { scrollToDiv } from '../../util/functionUtil'
import { mainBlue, mainButtonBackground, mainWhite, secondButtonBackground } from './theme'

export const PrimaryButton = styled((props) => (
    <Button
        variant='outlined'
        {...props}
        sx={{
            border: 'none !important',
            fontWeight: '400',
            fontSize: 18,
            padding: '1.2rem',
            height: '45px',
            color: props.white ? `${mainBlue} !important` : 'white !important',
            borderRadius: '1px',
            transition: 'all .2s ease-in-out',
            justifyContent: 'center',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            textTransform: 'none',
            lineHeight: 0,
            boxShadow: '0 .125rem .25rem rgba(35,38,45,.09)',
            // backgroundImage: !props.white && mainButtonBackground,
            backgroundColor: props.white ? `${mainWhite} !important` : `${mainBlue} !important`,
            '&:hover': {
                boxShadow: '0 1rem 3rem rgba(35,38,45,.275)',
                transform: 'scale(1.05)',
            },
            ...props.sx,
        }}
    />
))({

})

export const PrimaryButtonGroup = styled(({ ...other }) => <ButtonGroup variant='outlined' {...other} />)({
    border: `solid 1px ${mainWhite}`,
    borderRadius: '25px',
})

export const SelectButton = styled((props) => (
    <Button
        variant='outlined'
        sx={{
            border: props.first ? 'solid white !important' : props.last ? 'solid white !important' : '',
            borderWidth: (props.first && !props.mobile) ? '0px 1px 0px 0px !important' : (props.first && props.mobile) ? '0px 0px 1px 0px !important' : (props.last && !props.mobile) ? '0px 0px 0px 1px !important' : (props.last && props.mobile) ? '1px 0px 0px 0px !important' : '',
            borderRadius: (props.first && !props.mobile) ? '25px 0px 0px 25px' : (props.first && props.mobile) ? '25px 25px 0px 0px' : (props.last && !props.mobile) ? '0px 25px 25px 0px' : (props.last && props.mobile) ? '0px 0px 25px 25px' : '',
            fontWeight: '400',
            fontSize: !props.selected ? { md: 18, sm: 16, xs: 12 } : { md: 20, sm: 18, xs: 14 },
            padding: '1.2rem',
            color: 'white !important',
            transition: 'all .2s ease-in-out',
            justifyContent: 'center',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            textTransform: 'none',
            lineHeight: 0,
            boxShadow: '0 .125rem .25rem rgba(35,38,45,.09)',
            backgroundImage: (props.selected && props.first) ? secondButtonBackground : (props.selected && props.last) && mainButtonBackground,
            '&:hover': {
                boxShadow: '0 1rem 3rem rgba(35,38,45,.275)',
            },
        }}
        {...props}
    />
))({

})

export const PrimaryTextField = styled(({ ...other }) => <TextField variant='outlined' fullWidth {...other} />)({
    '& label.Mui-focused': {
        color: mainBlue,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: mainBlue,
        },
        '&.Mui-focused fieldset': {
            borderColor: mainBlue,
        },
    },
})

export const ContactButton = styled(({ ...other }) => (
    <PrimaryButton
        onClick={() => scrollToDiv('contactSection', 120)}
        {...other}
    >
        Contactez-nous
    </PrimaryButton>
))({})
