export const mainNeonBlue = '#48EBD2'
export const mainLinearBackground = 'linear-gradient(to right top, #143A9F, #2F80ED, #156BC6)'
export const mainCardLinearBackground = 'linear-gradient(to bottom, #13227E, #1343B5, #07166C)'
export const mainButtonBackground = 'linear-gradient(to right, #143A9F, #2F80ED, #156BC6)'
export const secondButtonBackground = 'linear-gradient(to left, #143A9F, #2F80ED, #156BC6)'
export const mainWhite = '#F8FAFF'
export const mainBlue = '#0260DE' // '#156BC6'
export const mainDarkBlue = '#0D1975'
export const mainGray = '#f0f0f0'
export const mainBlack = '#0e0e0e'
export const lightBlue = '#5C7EC4'
export const secondaryBlue = '#061064'
