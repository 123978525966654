import React from 'react'
import NorthRoundedIcon from '@mui/icons-material/NorthRounded'
import { mainBlue, mainWhite } from './styled/theme'

const Up = () => {
    return (
        <div id='scrollUp' onClick={() => window.scrollTo(0, 0)}>
            <NorthRoundedIcon
                sx={{
                    background: mainBlue,
                    color: mainWhite,
                    borderRadius: '50%',
                    padding: '0.5rem',
                    height: '1.5rem',
                    width: 'auto',
                    '&:hover': {
                        transform: 'scale(1.2)',
                        transition: 'all 0.2s ease-in-out',
                    },
                }}
            />
        </div>
    )
}

export default Up