import React, { useState } from 'react'
import whiteLogo from '../assets/logos/logo-white.png'
import { Grid } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import { mainDarkBlue, mainGray } from './styled/theme'
import { scrollToDiv } from '../util/functionUtil'

const accueil = 'accueil'
const productsSection = 'productsSection'
const presentationSection = 'presentationSection'
const keyFiguresSection = 'keyFiguresSection'
const servicesSection = 'servicesSection'
const contactSection = 'contactSection'

const LogoDiv = ({
    onClick = () => {},
    children,
}) => (
    <div onClick={onClick} style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
        {children}
    </div>
)

const MobileMenu = () => {
    const [selectedIcon, setSelectedIcon] = useState()

    const selectSection = (idSection) => {
        scrollToDiv(idSection, 25)
        setSelectedIcon(idSection)
    }

    return (
        <Grid id='menu' container justifyContent={'space-between'}>
            <Grid item xs={3} onClick={() => window.scrollTo(0, 0)}>
                <img id='app-logo' src={whiteLogo} alt='logo' />
            </Grid>
            <Grid item xs={1.5}>
                <div className='content'>
                    <div id='menuToggle'>
                        <input aria-label='' type='checkbox' />
                        <span />
                        <span />
                        <span />
                        <ul id='menuHidden2'>
                            <li>
                                <LogoDiv onClick={() => selectSection(accueil)}>
                                    {selectedIcon === accueil ? (
                                        <HomeOutlinedIcon sx={{ color: mainDarkBlue }} />
                                    ) : (
                                        <HomeOutlinedIcon sx={{ color: mainGray }} />
                                    )}
                                </LogoDiv>
                            </li>
                            <li>
                                <LogoDiv onClick={() => selectSection(productsSection)}>
                                    {selectedIcon === productsSection ? (
                                        <Inventory2OutlinedIcon sx={{ color: mainDarkBlue }} />
                                    ) : (
                                        <Inventory2OutlinedIcon sx={{ color: mainGray }} />
                                    )}
                                </LogoDiv>
                            </li>
                            <li>
                                <LogoDiv onClick={() => selectSection(presentationSection)}>
                                    {selectedIcon === presentationSection ? (
                                        <PersonOutlineOutlinedIcon sx={{ color: mainDarkBlue }} />
                                    ) : (
                                        <PersonOutlineOutlinedIcon sx={{ color: mainGray }} />
                                    )}
                                </LogoDiv>
                            </li>
                            <li>
                                <LogoDiv onClick={() => selectSection(keyFiguresSection)}>
                                    {selectedIcon === keyFiguresSection ? (
                                        <FactoryOutlinedIcon sx={{ color: mainDarkBlue }} />
                                    ) : (
                                        <FactoryOutlinedIcon sx={{ color: mainGray }} />
                                    )}
                                </LogoDiv>
                            </li>
                            <li>
                                <LogoDiv onClick={() => selectSection(servicesSection)}>
                                    {selectedIcon === servicesSection ? (
                                        <ArticleOutlinedIcon sx={{ color: mainDarkBlue }} />
                                    ) : (
                                        <ArticleOutlinedIcon sx={{ color: mainGray }} />
                                    )}
                                </LogoDiv>
                            </li>
                            <li>
                                <LogoDiv onClick={() => selectSection(contactSection)}>
                                    {selectedIcon === contactSection ? (
                                        <MarkAsUnreadOutlinedIcon sx={{ color: mainDarkBlue }} />
                                    ) : (
                                        <MarkAsUnreadOutlinedIcon sx={{ color: mainGray }} />
                                    )}
                                </LogoDiv>
                            </li>
                        </ul>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default MobileMenu