import React from 'react'
import whiteLogo from '../assets/logos/logo-white.png'
import { Grid } from '@mui/material'
import { ContactButton } from './styled/buttons'
import { scrollToDiv } from '../util/functionUtil'

const TopBar = () => (
    <Grid id='menu' container justifyContent={'space-between'}>
        <Grid item xs={2} onClick={() => window.scrollTo(0, 0)}>
            <img id='app-logo' src={whiteLogo} alt='logo' />
        </Grid>
        <Grid item xl={5.5} lg={6.5} md={8.5} style={{ textAlign: 'right' }}>
            <Grid container alignItems='center' justifyContent='space-between' style={{ height: '100%', textAlign: 'center' }}>
                <Grid container item xs={9}>
                    <Grid item md={3}>
                        <div onClick={() => scrollToDiv('productsSection', 25)} className='primaryLink'>Nos Produits</div>
                    </Grid>
                    <Grid item md={3}>
                        <div onClick={() => scrollToDiv('presentationSection', 120)} className='primaryLink'>A propos</div>
                    </Grid>
                    <Grid item md={3}>
                        <div onClick={() => scrollToDiv('keyFiguresSection', 50)} className='primaryLink'>Chiffres clé</div>
                    </Grid>
                    <Grid item md={3}>
                        <div onClick={() => scrollToDiv('servicesSection', 75)} className='primaryLink'>Nos packs</div>
                    </Grid>
                </Grid>
                <Grid item xs={2.5}>
                    <ContactButton sx={{ fontSize: '16px', width: '100%' }} />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

export default TopBar