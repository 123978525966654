import React, { useEffect } from 'react'
import '@splidejs/react-splide/css/skyblue'
import './App.css'
import Up from './components/Up'
import Home from './pages/Home'
import { StyledEngineProvider } from '@mui/material/styles'
import whiteLogo from './assets/logos/logo-white.png'
import blackLogo from './assets/logos/logo-black.png'
import { mainDarkBlue, mainWhite } from './components/styled/theme'

const App = () => {
    const getLogoHeight = (bodyScrolled) => {
        if (window.innerWidth > 780) {
            if (bodyScrolled) {
                return '2.5rem'
            }
            return '3rem'
        }
        if (bodyScrolled) {
            return '1.5rem'
        }
        return '2rem'
    }

    const handleScroll = () => {
        const scrollUp = document.getElementById('scrollUp')
        const primaryLinks = document.getElementsByClassName('primaryLink')
        const menu = document.getElementById('menu')
        const menuToggle = document.getElementById('menuToggle')
        const toggleSpans = menuToggle?.getElementsByTagName('span')
        const appLogo = document.getElementById('app-logo')
        const bodyScrolled = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50
        if (bodyScrolled) {
            if (primaryLinks) {
                for (let i=0; i<primaryLinks.length; i++) {
                    primaryLinks[i].style.fontSize = '16px'
                    primaryLinks[i].style.color = mainDarkBlue
                }
            }
            if (menu) {
                menu.style.background = mainWhite
                menu.style.boxShadow = '0 0 6px #85888C'
            }
            if (scrollUp) {
                scrollUp.style.right = '18px'
            }
            if (appLogo) {
                appLogo.setAttribute('src', blackLogo)
                appLogo.style.height = getLogoHeight(bodyScrolled)
            }
            if (toggleSpans) {
                for (let i=0; i<toggleSpans.length; i++) {
                    toggleSpans[i].style.backgroundColor = mainDarkBlue
                }
            }
        } else {
            if (primaryLinks) {
                for (let i=0; i<primaryLinks.length; i++) {
                    primaryLinks[i].style.fontSize = '18px'
                    primaryLinks[i].style.color = mainWhite
                }
            }
            if (menu) {
                menu.style.background = 'transparent'
                menu.style.boxShadow = 'none'
            }
            if (scrollUp) {
                scrollUp.removeAttribute('style')
            }
            if (appLogo) {
                appLogo.setAttribute('src', whiteLogo)
                appLogo.style.height = getLogoHeight(bodyScrolled)
            }
            if (toggleSpans) {
                for (let i=0; i<toggleSpans.length; i++) {
                    toggleSpans[i].style.backgroundColor = mainWhite
                }
            }
        }
    }

    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }

    useEffect(() => {
        window.onscroll = handleScroll
        window.addEventListener('resize', documentHeight)
        documentHeight()
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <Home />
            {window.innerWidth > 780 && (
                <Up />
            )}
        </StyledEngineProvider>
    )
}

export default App
